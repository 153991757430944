import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import '@/style/index.css'
import Vant from 'vant';
import 'vant/lib/index.css';
import i18n from '@/i18n'
import axios from "@/request/index,js";
import store from '@/store'

Vue.prototype.axios = axios

Vue.use(Vant);


Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
