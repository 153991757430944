<template>
  <div>
    <router-view></router-view>
    <van-tabbar route fixed z-index="999">
      <van-tabbar-item replace to="/home/index" icon="wap-home"></van-tabbar-item>
      <van-tabbar-item replace to="/home/list" icon="balance-list"></van-tabbar-item>
      <van-tabbar-item replace to="/home/order">
		  <template #icon="props">
		    <img src="@/assets/image/cart.png" style="width: 35px; height: 35px;" />
		  </template>
	  </van-tabbar-item>
      <van-tabbar-item replace to="/home/customer" icon="service"></van-tabbar-item>
      <van-tabbar-item replace to="/home/my" icon="friends"></van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
	
}
</script>

<style lang="scss" scoped></style>
