import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../Home.vue'
import Index from '@/views/Index.vue'
import { Toast } from 'vant';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/home/index'
  },
  {
    path:'/home',
    name: 'home',
    component: Home,
    children:[
      {
        path:'index',
        name:'index',
        component:Index
      },
      {
        path:'list',
        name:'list',
        component:() =>import('@/views/List.vue')
      },
      {
        path:'order',
        name:'order',
        component:() =>import('@/views/Order.vue')
      },
      {
        path:'customer',
        name:'customerst',
        component:() =>import('@/views/Customer.vue')
      },
      {
        path:'my',
        name:'my',
        component:() =>import('@/views/My.vue')
      }

    ]
  },
  {
    path:'/login',
    component:() =>import('@/views/login/Login.vue'),
    name:'login'
  },
  {
    path:'/register',
    component:() =>import('@/views/register/register.vue'),
    name:'register'
  },
  {
    path:'/address',
    component:() =>import('@/views/address/Address.vue'),
    name:'收货地址'
  },
  {
    path:'/popularize',
    component:() =>import('@/views/popularize/Popularize.vue'),
    name:'我要推广'
  },
  {
    path:'/accountDetails',
    component:() =>import('@/views/accountDetails/AccountDetails.vue'),
    name:'账户明细'
  },
  {
    path:'/teamReport',
    component:() =>import('@/views/teamReport/TeamReport.vue'),
    name:'团队报告'
  },
  {
    path:'/culture',
    component:() =>import('@/views/culture/Culture.vue'),
    name:'公司资质'
  },
  {
    path:'/recharge',
    component:() =>import('@/views/recharge/Recharge.vue'),
    name:'充值'
  },
  {
    path:'/withdrawal',
    component:() =>import('@/views/withDrawal/WithDrawal.vue'),
    name:'提现'
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next) =>{
   if(to.path == '/login' || to.path == '/register') return next();

   const token = localStorage.getItem('token')
   if(token) return next();

  //  没token，既未登录
  // Toast('去登录')
  router.push({name:'login'})
})

export default router
